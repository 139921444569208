import React,{useState,useEffect} from 'react'
import moment from 'moment'
import lodash from 'lodash'
import AnimatedNumber from "animated-number-react";
import BottomTab from './BottomTab';

export default function Dashboard (props) {

    const [user,setUser] = useState(localStorage.getItem("user"))
    const [showNotif,setShowNotif] = useState(false)


    useEffect(() => {
        if(showNotif){
            setTimeout(() => {
                setShowNotif(false)
            }, 5000);
        }
    }, [showNotif])

    return(
        <>  
      
        <BottomTab/>


    {showNotif ? 
    <div aria-live="assertive" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end mb-14">
  
      <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <svg class="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900">
                Changement effectué !
              </p>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>
  :null}

        

<div class="fixed inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
  

    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>



    <div class="inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
      
    <ul role="list" class="grid grid-cols-2 gap-4 ">

<li onClick={()=>{setShowNotif(true);setUser(1);localStorage.setItem("user",1)}} class="cursor-pointer col-span-1 bg-red-500 rounded-lg shadow divide-y divide-gray-200 ">
  <div class="w-full flex items-center justify-between p-6 space-x-6">
    <div class="flex-1 truncate">
      <div class="flex items-center justify-center space-x-3">
        <h3 class="text-white text-sm font-medium ">Valentin</h3>
      </div>
    </div>
  </div>
  
</li>

<li onClick={()=>{setShowNotif(true);setUser(2);localStorage.setItem("user",2)}} class="cursor-pointer col-span-1 bg-blue-500 rounded-lg shadow divide-y divide-gray-200">
  <div class="w-full flex items-center justify-between p-6 space-x-6">
    <div class="flex-1 truncate">
      <div class="flex items-center justify-center space-x-3">
        <h3 class="text-white text-sm font-medium ">Océane</h3>
      </div>
    </div>
  </div>
  
</li>

</ul>
    </div>
  </div>
</div>




            
        </>
    )
}