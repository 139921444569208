import moment from 'moment'
import lodash from 'lodash'
import { months } from '../components/datas/datas';

    //Verifie la validité de la date
    export const isValidDate=(dateString) => {
        var regEx = /^\d{4}-\d{2}-\d{2}$/;
        if(!dateString.match(regEx)) return false;  // Invalid format
        var d = new Date(dateString);
        var dNum = d.getTime();
        if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
        return d.toISOString().slice(0,10) === dateString;
    }

    //retour un tableau trier par date decroissant
    export const trierDateDecroissant = (array) => {
        const sortedArray  = array.sort((a,b) => new moment(b.date).format('YYYYMMDD')- new moment(a.date).format('YYYYMMDD'))
        return sortedArray
    }
    //retour un tableau trier par date croissant
    export const trierDateCroissant = (array) => {
        const sortedArray  = array.sort((a,b) => new moment(a.date).format('YYYYMMDD') - new moment(b.date).format('YYYYMMDD'))
        return sortedArray
    }

    //passe le format YYYY-MM-DD en DD/MM/YYYY
    export const formatDate=(date)=>{
        let ar= date.split("-")
        return ar[2] + "/" +ar[1] + "/" +ar[0]
    }

    //Retourne le nombre de jours entre deux dates
    export const totalJours=(dateDebut,dateFin)=>{
        var a  
        if(dateFin == ""){
            a = moment( moment().format("YYYY-MM-DD"), 'YYYY-MM-DD');
        }else{
            a = moment(dateFin, 'YYYY-MM-DD');
        }
       
        var b = moment(dateDebut, 'YYYY-MM-DD');
        var days = a.diff(b, 'days');
        return days
    }

    //Scroll en haut de la page
    export const scrollTop =()=>{
        window.scrollTo({top:0,behavior:'smooth'})
    }

    //Affiche le mois précédent
    export const showPreviousMonth = (actual)=>{

        switch(actual){
            case 0:
                return (
                    "Décembre"
                )
            case 1:
                return (
                    "Janvier"
                )
            case 2:
                return (
                    "Février"
                )
            case 3:
                return (
                    "Mars"
                )
            case 4:
                return (
                    "Avril"
                )
            case 5:
                return (
                    "Mai"
                )
            case 6:
                return (
                    "Juin"
                )
            case 7:
                return (
                    "Juillet"
                )
            case 8:
                return (
                    "Août"
                )
            case 9:
                return (
                    "Septembre"
                )
            case 10:
                return (
                    "Octobre"
                )
            case 11:
                return (
                    "Novembre"
                )
        }
    }

    //Affiche le mois suivant
    export const showNextMonth=(actual)=>{
        switch(actual){
            case 0:
                return (
                    "Février"
                )
            case 1:
                return (
                    "Mars"
                )
            case 2:
                return (
                    "Avril"
                )
            case 3:
                return (
                    "Mai"
                )
            case 4:
                return (
                    "Juin"
                )
            case 5:
                return (
                    "Juillet"
                )
            case 6:
                return (
                    "Août"
                )
            case 7:
                return (
                    "Septembre"
                )
            case 8:
                return (
                    "Octobre"
                )
            case 9:
                return (
                    "Novembre"
                )
            case 10:
                return (
                    "Décembre"
                )
            case 11:
                return (
                    "Janvier"
                )
            default :
            return ""
        }
    }

    export const getMonth = (index) => {
        let find = lodash.find(months,(m)=>{
            return m.index == index
        })
        return find.libelle
    }

    //Retourne si le film à été vu le jour-j ou en avant-premiere
    export const dayOneOrPreview=(date,vu)=>{
        if (moment(date).isSame(vu)){
            return "dayone"
        }else if(moment(vu).isBefore(date)) {
            return "preview"
        }else{
            return 'normal'
        }
    }


    export const showPrevious = (month,year) => {
        switch(month){
            case 0:
                return (
                    "Décembre "+ (year-1)
                )
            case 1:
                return (
                    "Janvier "+ year
                )
            case 2:
                return (
                    "Février "+ year
                )
            case 3:
                return (
                    "Mars "+ year
                )
            case 4:
                return (
                    "Avril "+ year
                )
            case 5:
                return (
                    "Mai "+ year
                )
            case 6:
                return (
                    "Juin "+ year
                )
            case 7:
                return (
                    "Juillet "+ year
                )
            case 8:
                return (
                    "Août "+ year
                )
            case 9:
                return (
                    "Septembre "+ year
                )
            case 10:
                return (
                    "Octobre "+ year
                )
            case 11:
                return (
                    "Novembre " + year
                )
        }
    }

    export const showNext = (month, year) => {
        switch(month){
            case 0:
                return (
                    "Février " + year
                )
            case 1:
                return (
                    "Mars " + year
                )
            case 2:
                return (
                    "Avril " + year
                )
            case 3:
                return (
                    "Mai " + year
                )
            case 4:
                return (
                    "Juin " + year
                )
            case 5:
                return (
                    "Juillet " + year
                )
            case 6:
                return (
                    "Août " + year
                )
            case 7:
                return (
                    "Septembre " + year
                )
            case 8:
                return (
                    "Octobre " + year
                )
            case 9:
                return (
                    "Novembre " + year
                )
            case 10:
                return (
                    "Décembre " + year
                )
            case 11:
                return (
                    "Janvier " + (year+1)
                )
            default :
            return ""
        }
    }