import React from 'react';


export default function NewCard ({openCreateCard}){


    return (
        <div onClick={openCreateCard} className="bg-third rounded-lg h-32 flex justify-center items-center border-2 border-third cursor-pointer hover:bg-secondary">
            <div className="w-full flex-col items-center px-4 pt-4 pb-3 ">
                <div className="flex-1 truncate">
                    <div className="flex justify-center items-center">
                        <h3 className="text-sm leading-5 font-medium truncate ml-3 text-white">Ajouter une carte</h3>
                    </div>
                </div>
                <div className="flex justify-center font-medium text-sm mt-1">
                </div>
            </div>
        </div>
    )
}