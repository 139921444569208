import React, { useState, useEffect } from 'react';
import BottomTab from './BottomTab';
import {ChevronDownIcon, ChevronUpIcon,ExclamationIcon} from '@heroicons/react/outline'
import { Disclosure } from '@headlessui/react'

export default function Facture(){

    const [search,setSearch] = useState("")

    const [factures,setFactures] = useState([])
    const [isList,setIsList] = useState(true)

    const [facture,setFacture] = useState({
        "libelle":"",
        "description":"",
        "montant":"",
        "user_id":null
     })

    useEffect(() => {
        getFactures()
        setFacture({...facture,user_id:localStorage.getItem("user")})
    }, []);

    const getFactures = () => {
        fetch(process.env.REACT_APP_API_URL+"/factures/getAll",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
            },
            body:JSON.stringify({user_id:localStorage.getItem("user")})
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            setFactures(json.factures)
            
            
        }).catch((error)=>{
            console.log(error)
        })
    }

    const addFacture = () => {
        fetch(process.env.REACT_APP_API_URL+"/factures/add",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
            },
            body:JSON.stringify(facture)
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
           getFactures()
           setIsList(true)
           setFacture({...facture,libelle:"",description:"",montant:""})
        }).catch((error)=>{
            console.log(error)
        })
    }

    console.log("Facture : ",facture)

    return (
        <>
         <div className='grid grid-cols-2  border-b border-third'>
            <div onClick={()=>setIsList(true)} className={(isList ? "bg-gray-300 " : "")+ ' cursor-pointer flex flex-row py-2 justify-center text-third border-r border-third'}>Mes factures</div>
            <div onClick={()=>setIsList(false)} className={(!isList ? "bg-gray-300 " : "")+  ' cursor-pointer flex flex-row py-2 justify-center text-third'}>Ajouter une facture</div>
        </div>
        <BottomTab/>
        {isList ? 
        <>
            <div className="mx-4 mt-2 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-300 text-gray-500 sm:text-sm">
                    Rechercher
                  </span>
                  <input
                    onChange={(e)=>setSearch(e.target.value)}
                    value={search}
                    type="text"
                    name="libelle"
                    id="libelle"
                    className="flex-1 block w-full focus:ring-third focus:border-third min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                  />
                </div>

            {factures.length == 0 ? 
                <div className='flex flex-row justify-center items-center mt-8'>
                    <span className='text-white text-lg'>Il n'y a pas de facture</span>
                </div>
            : null}

        <div className='px-4 pb-6 mt-4 mb-10'>
        

            <ul role="list" className="divide-y divide-gray-200">
                {factures.map((f, i) => {
                    
                    if(f.libelle.toLowerCase().search(search.toLowerCase()) === -1){
                        return null
                    }

                    return (
                        <li key={i}>
                            <div className="px-4 py-4 sm:px-6">
                                <div className="flex items-center justify-between">
                                    <p className="text-sm font-medium text-third truncate">
                                        {f.libelle}
                                    </p>
                                </div>

                                <Disclosure>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button as="div" className={"flex h-1 flex-row justify-end"}>
                                        
                                        {open ?
                                            <ChevronUpIcon className="h-5 w-5 text-white"/>
                                        :
                                            <ChevronDownIcon className="h-5 w-5 text-white"/>
                                        }
                                        
                                    </Disclosure.Button>
                                    <div className="mt-2 sm:flex sm:justify-between">
                                        <div className="sm:flex">
                                            <p className="flex items-center text-sm text-gray-500 whitespace-pre">
                                            <svg className="h-5 w-5 text-third"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                            </svg>
                                                <span className='ml-2'>{f.montant} €</span>
                                            </p>
                                        </div>
                                    </div>
                                    <Disclosure.Panel>
                                       {f.description ? 
                                            <div className='text-white mt-2 ml-4'>
                                                {f.description}
                                            </div>
                                        :null}
                                       
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                                
                                
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
        </>
    : 
   
                
    <div className="flex items-center justify-center mt-8 pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="border border-transparent inline-block align-bottom bg-gray-300 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        
       

        <div className="flex flex-row ">
            <div className="w-full">
                <label htmlFor="prix" className="block text-sm font-medium leading-5 text-third">
                    Libelle
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                    <input value={facture.libelle}  onChange={(e)=>setFacture({...facture,libelle:e.target.value})}  type="text" id="prix" placeholder="Nom de la facture" className=" w-full my-2 bg-secondary text-white placeholder-white focus:outline-none focus:shadow-outline border border-transparent rounded-lg py-2 px-4 block appearance-none leading-normal" />
                </div>
            </div>
            <div  className="w-full">
                <label htmlFor="email" className="ml-2 block text-sm font-medium leading-5 text-third">
                    Montant
                </label>
                <div className="mt-1 ml-2">
                    <input autoComplete="off" value={facture.montant} onChange={(e)=>setFacture({...facture,montant:e.target.value})}  type="number" id="distance" placeholder="Prix en €" className=" w-full my-2 bg-secondary placeholder-white text-white focus:outline-none focus:shadow-outline border border-transparent rounded-lg py-2 px-4 block appearance-none leading-normal" />
                </div>
            </div>        
        </div>
            <div className="flex flex-col">
                <label htmlFor="description" className="block text-sm font-medium leading-5 text-third">
                    Description
                </label>
                <textarea
                  id="description"
                  name="description"
                  rows={3}
                  value={facture.description}
                  className="max-w-lg shadow-sm block w-full bg-secondary placeholder-white text-white sm:text-sm border  rounded-md"
                  onChange={(e)=>setFacture({...facture,description:e.target.value})}
                />
            </div>
          

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
                <span className="flex w-full rounded-md shadow-sm ">
                <button onClick={addFacture}  type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-valid text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    Ajouter la facture
                </button>
                </span>
               
            </div>
        </div>
    </div>
    
    }
        

       </>
    )
}




