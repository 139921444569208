import React,{useState,useEffect} from 'react'
import { withRouter,Link,useLocation } from "react-router-dom";

export default withRouter((props)=>{

    let location = useLocation();

    return(
        

        <div className="w-full ">
            {/*<section id="bottom-navigation" className="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow">*/} 
            <section id="bottom-navigation" className={(localStorage.getItem('user') == 1 ? " border-red-700 " : localStorage.getItem('user') == 2 ? " border-blue-700 " : " border-secondary " )+" block fixed inset-x-0 bottom-0 z-10 bg-primary h-14 shadow border-t-2 "}>
                <div id="tabs" className="flex justify-between">
                    <Link to="/dashboard" className="w-full justify-center inline-block text-center py-2">
                        <svg className={(location.pathname == "/dashboard" ? "text-third " : "text-secondary " )+ " inline-block py-1 h-8 w-8"}  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="1"  strokeLinecap="round"  strokeLinejoin="round">  <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />  <rect x="8" y="2" width="8" height="4" rx="1" ry="1" /></svg>
                    </Link>
                    <Link to="/essence" className="w-full justify-center inline-block text-center py-2">
                        <svg className={(location.pathname == "/essence" ? "text-third " : "text-secondary " )+ " inline-block py-1 h-8 w-8"}  width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z"/>
                            <circle cx="7" cy="17" r="2" />
                            <circle cx="17" cy="17" r="2" />
                            <path d="M5 17h-2v-6l2-5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" />
                        </svg>
                    </Link>
                    <Link to="/graph" className="w-full justify-center inline-block text-center py-2">
                        <svg className={(location.pathname == "/graph" ? "text-third " : "text-secondary " )+ " inline-block py-1 h-8 w-8"}  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"/>
                        </svg>
                    </Link>
                    <Link to="/factures" className="w-full justify-center inline-block text-center py-2">
                        <svg className={(location.pathname == "/factures" ? "text-third " : "text-secondary " )+ " inline-block py-1 h-8 w-8"} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
                        </svg>
                    </Link>
                    <Link to="/user" className="w-full justify-center inline-block text-center py-2">
                        <svg className={(location.pathname == "/user" ? "text-third " : "text-secondary " )+ " inline-block py-1 h-8 w-8"}  width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z"/>
                            <circle cx="9" cy="7" r="4" />
                            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                            <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                        </svg>
                    </Link>
                </div>
            </section>
        </div>
    )
})
