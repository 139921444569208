import React from 'react';


export default function CreateCard({card,onChange,error,addCard,goBack}){

    return (
        <>
            <div className="fixed z-10 inset-0 overflow-y-auto">
                
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>


                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

                    <div className="border border-transparent inline-block align-bottom bg-primary rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    
                    {error ? 
                        <div className="rounded-md bg-red-200 p-4 mb-3">
                            <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                </svg>
                            </div>
                                <div className="ml-3">
                                    <h3 className="text-sm leading-5 font-medium text-red-800">
                                        {error}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    : null}

                    <div className="flex flex-row ">
                        <div className="w-full">
                            <label htmlFor="prix" className="block text-sm font-medium leading-5 text-third">
                                Prix
                            </label>
                            <div className="mt-1 rounded-md shadow-sm">
                                <input autoComplete="off" onChange={(e)=>onChange(e)}  type="number" id="prix" placeholder="Prix en €" value={card.prix} className=" w-full my-2 bg-secondary text-white placeholder-white focus:outline-none focus:shadow-outline border border-transparent rounded-lg py-2 px-4 block appearance-none leading-normal" />
                            </div>
                        </div>
                        <div  className="w-full">
                            <label htmlFor="email" className="ml-2 block text-sm font-medium leading-5 text-third">
                                Distance
                            </label>
                            <div className="mt-1 ml-2">
                                <input autoComplete="off" onChange={(e)=>onChange(e)}  type="number" id="distance" placeholder="Distance en Km" value={card.distance} className=" w-full my-2 bg-secondary placeholder-white text-white focus:outline-none focus:shadow-outline border border-transparent rounded-lg py-2 px-4 block appearance-none leading-normal" />
                            </div>
                        </div>        
                    </div>
                        <div className="flex flex-row">
                            <div className="w-full">
                                <label htmlFor="email" className="block text-sm font-medium leading-5 text-third">
                                    Date du plein
                                </label>
                                <div className="mt-1 ">
                                    <input autoComplete="off" onChange={(e)=>onChange(e)} type="date" placeholder="YYYY-MM-DD " id="dateDebut" value={card.dateDebut} className={(card.dateDebut == "" ? "h-11 ": " ")+" w-full my-2 mr-2 bg-secondary text-white placeholder-white focus:outline-none focus:shadow-outline border border-transparent rounded-lg py-2 px-4 block appearance-none leading-normal"} />
                                </div>
                            </div>
                            <div  className="w-full">
                                <label htmlFor="email" className="ml-2 block text-sm font-medium leading-5 text-third">
                                    Date nouveau plein
                                </label>
                                <div className="mt-1 ml-2 ">
                                    <input autoComplete="off" onChange={(e)=>onChange(e)} type="date" placeholder="YYYY-MM-DD " id="dateFin" value={card.dateFin} className={(card.dateFin == "" ? "h-11 ": " ")+" w-full my-2 bg-secondary text-white placeholder-white focus:outline-none focus:shadow-outline border border-transparent rounded-lg py-2 px-4 block appearance-none leading-normal"} />
                                </div>
                            </div>        
                        </div>
                        <div className="flex flex-row">
                            <div className="w-full">
                                <label htmlFor="litres" className=" block text-sm font-medium leading-5 text-third">
                                    Litres
                                </label>
                                <div className="mt-1">
                                    <input onChange={(e)=>onChange(e)}  type="text" id="litres" placeholder="Litres" value={card.litres} className=" w-full my-2 bg-secondary text-white focus:outline-none focus:shadow-outline border border-transparent rounded-lg py-2 px-4 block appearance-none leading-normal" />
                                </div>
                            </div>
                                   
                        </div>

                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                            <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                            <button onClick={()=>addCard()} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-valid text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Ajouter la carte
                            </button>
                            </span>
                            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                            <button  onClick={()=>goBack()} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-back text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Retour
                            </button>
                            </span>
                        </div>
                    </div>
                </div>
                </div>
            
                    
                   
       </>
    )
}



