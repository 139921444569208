import React from 'react';
import {Router,Route,Switch,Redirect} from 'react-router-dom'
import { createBrowserHistory } from 'history'


import Dashboard from './Dashboard'
import Essence from './Essences/Essence';
import Graph from './Essences/Graph'
import User from "./User"
import Facture from './Facture'

const customHistory = createBrowserHistory()

//Routing de l'application web
const Routing =()=> (
        <Router history={customHistory} >
            <Switch>
                <Route exact component={Dashboard} path="/dashboard" />
                <Route exact component={Essence} path="/essence" />
                <Route exact component={Graph} path="/graph" />
                <Route exact component={Facture} path="/factures" />
                <Route exact component={User} path="/user" />
                <Route exact path='*'><Redirect to="/dashboard" /></Route>
            </Switch>
        </Router>
)
export default Routing
