

export const months = [
    {
        index:0,
        libelle:'Janvier'
    },
    {
        index:1,
        libelle:'Février'
    },
    {
        index:2,
        libelle:'Mars'
    },
    {
        index:3,
        libelle:'Avril'
    },
    {
        index:4,
        libelle:'Mai'
    },
    {
        index:5,
        libelle:'Juin'
    },
    {
        index:6,
        libelle:'Juillet'
    },
    {
        index:7,
        libelle:'Août'
    },
    {
        index:8,
        libelle:'Septembre'
    },
    {
        index:9,
        libelle:'Octobre'
    },
    {
        index:10,
        libelle:'Novembre'
    },
    {
        index:11,
        libelle:'Décembre'
    },
]


export const movies = [
        {
            id:1,
            titre:"Le Roi Lion",
            date:"2019-07-17",
            vu:"2019-07-23"
        },
        {
            id:2,
            titre:"Spider-Man : Far From Home",
            date:"2019-07-03",
            vu:"2019-07-03"
        },
        {
            id:3,
            titre:"Coutdown",
            date:"2019-11-13",
            vu:"2019-11-30"
        },
        {
            id:4,
            titre:"Retour à Zombieland",
            date:"2019-10-30",
            vu:"2019-10-25"
        },
        {
            id:5,
            titre:"Wedding Nightmare",
            date:"2019-08-28",
            vu:"2019-09-28"
        },
        {
            id:6,
            titre:"Inseparables",
            date:"2019-09-04",
            vu:"2019-09-13"
        },
        {
            id:7,
            titre:"John Wick Parabellum",
            date:"2019-05-22",
            vu:"2019-05-23"
        },
        {
            id:8,
            titre:"Avengers Endgame",
            date:"2019-04-24",
            vu:"2019-05-05"
        },
        {
            id:9,
            titre:"Captain Marvel",
            date:"2019-03-06",
            vu:"2019-03-07"
        },
        {
            id:10,
            titre:"Le Chant Du Loup",
            date:"2019-02-20",
            vu:"2019-02-20"
        },
        {
            id:11,
            titre:"Nikcy Larson et Le Parfum de Cupidon",
            date:"2019-02-06",
            vu:"2019-02-06"
        },
        {
            id:12,
            titre:"Les Animaux Fantastiques : Les Crimes de Grindelwald",
            date:"2018-11-14",
            vu:"2019-11-30"
        },
        {
            id:13,
            titre:"Venom",
            date:"2019-10-03",
            vu:"2019-10-16"
        },
        {
            id:14,
            titre:"Braqueurs D'élites",
            date:"2018-08-29",
            vu:"2018-08-29"
        },
        {
            id:15,
            titre:"Darkest Minds : Rebellion",
            date:"2018-08-05",
            vu:"2018-08-17"
        },
        {
            id:16,
            titre:"Mission Impossible : Fallout",
            date:"2018-08-01",
            vu:"2018-08-04"
        },
        {
            id:17,
            titre:"Ant-Man et La Guepe",
            date:"2018-07-12",
            vu:"2018-07-20"
        },
        {
            id:18,
            titre:"Les Indestructibles 2",
            date:"2018-07-04",
            vu:"2018-07-07"
        },
        {
            id:19,
            titre:"Deadpool 2",
            date:"2018-05-14",
            vu:"2018-05-23"
        },
        {
            id:20,
            titre:"Rampage : Hors De Controle",
            date:"2018-04-07",
            vu:"2018-05-05"
        },
        {
            id:21,
            titre:"Avengers : Infinity War",
            date:"2018-04-25",
            vu:"2018-04-25"
        },
        {
            id:22,
            titre:"Tomb Raider",
            date:"2018-03-14",
            vu:"2018-03-30"
        },
        {
            id:23,
            titre:"Black Panther",
            date:"2018-02-14",
            vu:"2018-02-13"
        },
        {
            id:24,
            titre:"Downsizing",
            date:"2017-12-18",
            vu:"2018-01-20"
        },
        {
            id:25,
            titre:"Jumanji",
            date:"2017-12-01",
            vu:"2018-01-02"
        },
        {
            id:26,
            titre:"Geostorm",
            date:"2017-10-18",
            vu:"2017-11-18"
        },
        {
            id:27,
            titre:"Epouse Moi Mon Pote",
            date:"2017-10-25",
            vu:"2017-10-25"
        },
        {
            id:28,
            titre:"Seven Sisters",
            date:"2017-08-30",
            vu:"2017-10-24"
        },
        {
            id:29,
            titre:"KingsMan : Le Cerle D'Or",
            date:"2017-09-22",
            vu:"2017-10-21"
        },
        {
            id:30,
            titre:"La Planètes Des Singes : Suprématie",
            date:"2017-07-23",
            vu:"2017-08-10"
        },
        {
            id:31,
            titre:"Spider-Man : Homecoming",
            date:"2017-06-12",
            vu:"2017-08-01"
        },
        {
            id:32,
            titre:"Moi Moche et Méchant 3",
            date:"2017-06-15",
            vu:"2017-07-06"
        },
        {
            id:33,
            titre:"Wonder Woman",
            date:"2017-05-20",
            vu:"2017-06-10"
        },
        {
            id:34,
            titre:"Pirates Des Caraïbes : La Vengeance de Salazar",
            date:"2017-05-20",
            vu:"2017-05-26"
        },
        {
            id:35,
            titre:"Ghost In The Shell",
            date:"2017-03-29",
            vu:"2017-03-31"
        },
        {
            id:36,
            titre:"Logan",
            date:"2017-03-01",
            vu:"2017-03-04"
        },
        {
            id:37,
            titre:"Alibi.com",
            date:"2017-02-15",
            vu:"2017-02-15"
        },
        {
            id:38,
            titre:"Le Lion",
            date:"2020-01-29",
            vu:"2020-02-01"
        },
        {
            id:39,
            titre:"1917",
            date:"2020-01-15",
            vu:"2020-01-15"
        },
        {
            id:40,
            titre:"Jumanji : Next Level",
            date:"2019-12-04",
            vu:"2019-12-22"
        },
        {
            id:41,
            titre:"Nightmare Island",
            date:"2020-02-12",
            vu:"2020-02-14"
        },
        {
            id:42,
            titre:"Toy Story 4",
            date:"2019-06-26",
            vu:"2019-06-23"
        },
        {
            id:43,
            titre:"Divorce club",
            date:"2020-07-14",
            vu:"2020-08-07"
        },



    ]
