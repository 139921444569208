import React,{useState, useEffect} from 'react';
import moment from 'moment'
import {months} from '../datas/datas'
import lodash from 'lodash'
import CardEssence from './CardEssence'
import NewCard from './NewCard';
import CreateCard from './CreateCard';
import { isValidDate,trierDateCroissant,showNextMonth,showPreviousMonth} from '../../functions/utils'
import EditedCard from './EditedCard';
import BottomTab from '../BottomTab';

export default function Essence (props){

    const [month,setMonth] = useState(moment().month())
    const [year,setYears] = useState(moment().year())
    const [essences,setEssences] = useState()
    const [created,setCreated] = useState(false)
    const [edited,setEdited] = useState(false)
    const [error,setError] = useState(false)

    const [editCard,setEditCard] = useState(
        {
            prix:"",
            distance:"",
            dateDebut:"",
            dateFin:"",
            litres:0,
            user_id:localStorage.getItem("user")
        }
    )


    useEffect(() => {
        fetchEssences()
    }, []);


    function fetchEssences(){
        fetch(process.env.REACT_APP_API_URL+"/essence/getAll",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
            },
            body:JSON.stringify({user_id:localStorage.getItem("user")})
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            if(json.err){
                setError(json.err)
            }else{
                console.log(json)
                setEssences(json.essences)
            }
            
        }).catch((error)=>{
            console.log(error)
        })
    }

    function getMonthly(month,year){
        let tab = []
        lodash.forEach(essences,(d)=>{
            let date = d.dateDebut.split("-")
            if(date[1] == (month+1) && date[0] == year){
                tab.push(d)
            }
        })
        return tab
    }
      //Pässe au mois précédent
    function previous(){
        if(month > 0 ){
            setMonth(month - 1)
        }
    }

    //Passe au mois suivant
    function next(){
        if(month < 11 ){
            setMonth(month + 1)
        }
    }

    function onChangeCard(e){
        console.log(e.target.value)
        setEditCard({...editCard,[e.target.id]:e.target.value}) 
        
    }

    function addCard(){
        if(editCard.prix == "" || editCard.dateDebut == "" || isValidDate(editCard.dateDebut)==false){
            setError("Des champs obligatoire n'ont pas été remplis.")
        }else{
            setError("")
            if(editCard.distance == ""){
                editCard.distance=0
            }
            fetch(process.env.REACT_APP_API_URL+"/essence/add",{
                method:'POST',
                headers:{
                    "Content-Type":"application/json",
                },
                body:JSON.stringify({...editCard,litres:editCard.litres.replace(',','.')})
            })
            .then((response)=>{
                return response.json()
            })
            .then((json)=>{
                setEditCard( {
                    prix:"",
                    distance:"",
                    dateDebut:"",
                    dateFin:"",
                    litres:0,
                    user_id:localStorage.getItem("user")
                })
                setCreated(false)
                fetchEssences()
                
            }).catch((error)=>{
                console.log(error)
            })
        }
    }

    function openCreateCard(){
        setCreated(true)
    }

    function openEditEssence(id){
        let film
        lodash.forEach(essences,(e)=>{
            if ( e.id == id){
                film = e
            }
        })
        setEdited(true)
        setEditCard(film)
    }

    function goBack(){
        setCreated(false)
        setEdited(false)
        setEditCard( {
            prix:"",
            distance:"",
            dateDebut:"",
            dateFin:"",
            litres:0,
            user_id:localStorage.getItem("user")
        })
    }

    function updateCard(){

        if(editCard.prix == "" || editCard.dateDebut == "" || isValidDate(editCard.dateDebut)==false || (editCard.dateFin && isValidDate(editCard.dateFin) == false) ){
            setError("Des champs obligatoire n'ont pas été remplis.")
        }else{

            setError("")
            fetch(process.env.REACT_APP_API_URL+"/essence/update/"+editCard.id,{
                method:'POST',
                headers:{
                    "Content-Type":"application/json",
                },
                body:JSON.stringify(editCard)
            })
            .then((response)=>{
                return response.json()
            })
            .then((json)=>{
                if(!json.err){
                    setEditCard( {
                        prix:"",
                        distance:"",
                        dateDebut:"",
                        dateFin:"",
                        litres:0,
                        user_id:localStorage.getItem("user")
                    })
                    setEdited(false)
                    fetchEssences()
                }else{
                    setError(json.msg)
                }
            }).catch((error)=>{
                console.log(error)
                setError(error)
            })
        }
    }

    function deleteCard(){
        fetch(process.env.REACT_APP_API_URL+"/essence/delete/"+editCard.id,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
              },
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            if(!json.err){
                setEditCard( {
                    prix:"",
                    distance:"",
                    dateDebut:"",
                    dateFin:"",
                    litres:0,
                    user_id:localStorage.getItem("user")
                })
                setEdited(false)
                fetchEssences()
            }else{
                setError(json.msg)
            }
        }).catch((error)=>{
            console.log(error)
            setError(error)
        })
    }


    return (
        <>
        <BottomTab/>
        {created ? 
            <CreateCard onChange={onChangeCard} card={editCard} error={error} addCard={addCard} goBack={goBack} />
        : null}

        {edited ? 

            <EditedCard onChange={onChangeCard} card={editCard} error={error} addCard={addCard} goBack={goBack} updateCard={updateCard} deleteCard={deleteCard}/>
        : null}

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-4 h-full">
            <nav  className="mb-4 px-4 flex items-center justify-between sm:px-0">
                
                <div className={"w-0 flex-1 flex"}>
                    <div onClick={()=>setYears(year - 1)} className="text-white -mt-px border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm leading-5 font-medium cursor-pointer transition ease-in-out duration-150">
                        <svg className="mr-3 h-5 w-5 text-third" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
                        </svg>
                        {year-1}
                    </div>
                </div>

                <div className="flex text-third justify-center -mt-px border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm leading-5 font-medium cursor-pointer transition ease-in-out duration-150">
                    {year}
                </div>

                <div className={"w-0 flex-1 flex justify-end"}>
                    <div onClick={()=>setYears(year + 1)} className="text-white -mt-px border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm leading-5 font-medium cursor-pointer transition ease-in-out duration-150">
                        {year + 1}
                        <svg className="ml-3 h-5 w-5 text-third" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>
         </nav>

        <nav className="mb-4 px-4 flex items-center justify-between sm:px-0">
                <div className={(month == 0 && "invisible ") + "w-0 flex-1 flex"}>
                    <div onClick={previous} className="text-white -mt-px border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm leading-5 font-medium cursor-pointer transition ease-in-out duration-150">
                        <svg className="mr-3 h-5 w-5 text-third" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
                        </svg>
                        {showPreviousMonth(month)}
                    </div>
                </div>
            
            <div className="text-third flex justify-center -mt-px border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm leading-5 font-medium cursor-pointer transition ease-in-out duration-150">
                {months.map((m,i)=>{
                    if(month == m.index){
                        return (
                            <div key={i}>{m.libelle}</div>
                        )
                    }
                })}
            </div>
            <div className={(month == 11 && "invisible ")+"w-0 flex-1 flex justify-end"}>
                <div onClick={next} className="text-white -mt-px border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm leading-5 font-medium cursor-pointer transition ease-in-out duration-150">
                    {showNextMonth(month)}
                    <svg className="ml-3 h-5 w-5 text-third" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </div>
            </div>
        </nav>


            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <NewCard openCreateCard={openCreateCard} />
                
                {trierDateCroissant(getMonthly(month,year)).map((d,i)=>{
                    console.log(d)
                    return (
                        <CardEssence key={i} id={d.id} prix={d.prix} distance={d.distance} litres={d.litres} dateDebut={d.dateDebut} dateFin={d.dateFin} editEssence={openEditEssence}/>
                    )
                })}

            </div>
        </div>
        </>
    )
}


