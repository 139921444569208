import React,{useState,useEffect} from 'react'
import moment from 'moment'
import lodash from 'lodash'
import AnimatedNumber from "animated-number-react";
import BottomTab from './BottomTab';

export default function Dashboard (props) {

    
    const [month,setMonth] = useState(moment().month())
    const [year,setYears] = useState(moment().year())
    const [essences,setEssences] = useState(null)
    const [errorEssence,setErrorEssence] = useState("")
    const [error,setError] = useState("")
    const [modal,setModal] = useState(false)
    const [user,setUser] = useState(localStorage.getItem("user"))
    const [factures,setFactures] = useState([])

    useEffect(() => {
        if(localStorage.getItem("user")==null){
            setModal(true)
        }else{
            fetchEssences()
            fetchFactures()
            setModal(false)
        }
        
    }, [user]);

    function fetchEssences(){
        fetch(process.env.REACT_APP_API_URL+"/essence/getAll",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
            },
            body:JSON.stringify({user_id:localStorage.getItem("user")})
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            if(json.err){
                setError(json.err)
            }else{
                setEssences(json.essences)
            }
            
        }).catch((error)=>{
            console.log(error)
        })
    }

    function fetchFactures(){
        fetch(process.env.REACT_APP_API_URL+"/factures/getAll",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
            },
            body:JSON.stringify({user_id:localStorage.getItem("user")})
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            if(json.err){
                setError(json.err)
            }else{
                setFactures(json.factures)
            }
            
        }).catch((error)=>{
            console.log(error)
        })
    }

    function getMonthlyKm(month){
        let tab = []
        lodash.forEach(essences,(e)=>{
            let date = e.dateDebut.split("-")
            if(date[1] == (month+1) && date[0] == year){
                if(e.distance != ""){
                    tab.push(parseInt(e.distance))
                }
            }
        })
        return lodash.sum(tab)
    }

    function getMonthlyPlein(month){
        let tab = []
        lodash.forEach(essences,(e)=>{
            let date = e.dateDebut.split("-")
            if(date[1] == (month+1) && date[0] == year){
                tab.push(e)
            }
        })
        return tab.length
    }

    function getMonthlyDepense(month){
        let tab = []
        lodash.forEach(essences,(e)=>{
            let date = e.dateDebut.split("-")
            if(date[1] == (month+1) && date[0] == year){
                    tab.push(parseFloat(e.prix))
            }
        })
        return lodash.sum(tab)
    }

    function getYearlyKm(year){
        let tab = []
        lodash.forEach(essences,(e)=>{
            let date = e.dateDebut.split("-")
            if(date[0] == year){
                if(e.distance != ""){
                    tab.push(parseInt(e.distance))
                }
            }
        })
        return lodash.sum(tab)
    }

    function getYearlyPlein(year){
        let tab = []
        lodash.forEach(essences,(e)=>{
            let date = e.dateDebut.split("-")
            if(date[0] == year){
                tab.push(e)
            }
        })
        return tab.length
    }

    function getYearlyDepense(year){
        let tab = []
        lodash.forEach(essences,(e)=>{
            let date = e.dateDebut.split("-")
            if(date[0] == year){
                tab.push(parseFloat(e.prix))
            }
        })
        return lodash.sum(tab)
    }

    function getAllDepense(){
        let tab = []
        lodash.forEach(essences,(e)=>{
            tab.push(parseFloat(e.prix))
        })
        return lodash.sum(tab)
    }

    function getAllKm(){
        let tab = []
        lodash.forEach(essences,(e)=>{
            if(e.distance != ""){
                tab.push(parseFloat(e.distance))
            }
        })
        return lodash.sum(tab)
    }

    function getAllFacture(){
        let tab = []
        lodash.forEach(factures,(f)=>{
            if(f.montant != ""){
                tab.push(parseFloat(f.montant))
            }
        })
        return lodash.sum(tab)
    }

    function getAllPlein(){
        if(essences){
            return essences.length
        }else{
            return 0
        }
        
    }




    return(
        <>  
        {modal ?
        <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        

            <div class="inline-block align-bottom bg-white rounded-lg px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div class=" mt-4 md:mt-0 grid grid-cols-2 gap-4">
                <button onClick={()=>{setUser(1);localStorage.setItem("user",1)}} type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                  Valentin
                </button>
                <button onClick={()=>{setUser(2);localStorage.setItem("user",2)}} type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                  Océane
                </button>
              </div>
            </div>
          </div>
        </div>
        
    :null}
        <BottomTab/>
        <div className="px-6 pb-6 mb-10">

            <div className="my-3">
                <h3 className="text-lg leading-6 my-4 font-bold underline text-third text-center">
                    Ce mois-ci
                </h3>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Nombre de pleins faits
                                </dt>
                                <dd className="flex mt-1 justify-center text-3xl leading-9 font-semibold text-gray-900">
                                    <AnimatedNumber
                                        value={getMonthlyPlein(month)}
                                        formatValue={(value) => value.toFixed()}
                                        duration={500}
                                    />
                                </dd>
                                  
                               
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Total dépensés
                                </dt>
                                
                                    <dd className="flex mt-1 justify-center text-3xl leading-9 font-semibold text-gray-900">
                                    <AnimatedNumber
                                        value={getMonthlyDepense(month)}
                                        formatValue={(value) => value.toFixed()}
                                        duration={500}
                                    />
                                         <span className="ml-2">€</span>
                                        
                                    </dd>
                                   
                                
                                
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Kms effectués
                                </dt>
                                
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                        <AnimatedNumber
                                        value={getMonthlyKm(month)}
                                        formatValue={(value) => value.toFixed()}
                                        duration={500}
                                    />
                                              <span className="ml-2">kms</span>
                                        </dd>
                                   
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className="my-3">
                <h3 className="text-center my-4 text-lg leading-6 font-bold underline text-third">
                    Cette année
                </h3>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Nombre de pleins faits
                                </dt>
                               
                                   
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                        <AnimatedNumber
                                        value={getYearlyPlein(year)}
                                        formatValue={(value) => value.toFixed()}
                                        duration={500}
                                    />
                                        </dd>
                                 
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Total dépensés
                                </dt>
                               
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                        <AnimatedNumber
                                        value={getYearlyDepense(year)}
                                        formatValue={(value) => value.toFixed()}
                                        duration={500}
                                    />
                                    <span className="ml-2">€</span>
                                        </dd>
                                   
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Kms effectués
                                </dt>
                               
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                        <AnimatedNumber
                                        value={getYearlyKm(year)}
                                        formatValue={(value) => value.toFixed()}
                                        duration={500}
                                    />
                                             <span className="ml-2">kms</span>
                                        </dd>
                                   
                            </dl>
                        </div>
                    </div>
                   
                </div>
            </div>

            <div className="my-3">
                <h3 className="text-center my-4 text-lg leading-6 font-bold underline text-third">
                    Depuis toujours
                </h3>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Nombre de pleins faits
                                </dt>

                               
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                        <AnimatedNumber
                                        value={getAllPlein()}
                                        formatValue={(value) => value.toFixed()}
                                        duration={500}
                                    />
                                        </dd>
                                   
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Total essence dépensés
                                </dt>
                                
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                        <AnimatedNumber
                                        value={getAllDepense()}
                                        formatValue={(value) => value.toFixed(2)}
                                        duration={500}
                                    />
                                             <span className="ml-2">€</span>
                                        </dd>
                                  
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Kms effectués
                                </dt>
                               
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                        <AnimatedNumber
                                        value={getAllKm()}
                                        formatValue={(value) => value.toFixed()}
                                        duration={500}
                                    />
                                             <span className="ml-2">kms</span>
                                        </dd>
                                  
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Total dépensés
                                </dt>
                               
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                        <AnimatedNumber
                                        value={getAllDepense()+getAllFacture()}
                                        formatValue={(value) => value.toFixed(2)}
                                        duration={500}
                                    />
                                             <span className="ml-2">€ </span>
                                        </dd>
                                  
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Total factures
                                </dt>
                               
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                        <AnimatedNumber
                                        value={getAllFacture()}
                                        formatValue={(value) => value.toFixed(2)}
                                        duration={500}
                                    />
                                    <span className="ml-2">€ </span>
                                        </dd>
                                  
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Coût par km
                                </dt>
                               
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                        <AnimatedNumber
                                        value={(getAllDepense()+getAllFacture())/getAllKm()}
                                        formatValue={(value) => value.toFixed(4)}
                                        duration={500}
                                    />
                                             <span className="ml-2">€ / km</span>
                                        </dd>
                                  
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            


        </div>
            
        </>
    )
}