import React,{useState, useEffect} from 'react';
import moment from 'moment'
import lodash, { set } from 'lodash'
import { Line,Bar,Doughnut } from 'react-chartjs-2';
import {trierDateCroissant,showNextMonth,showPreviousMonth} from '../../functions/utils'
import {months} from '../datas/datas'
import BottomTab from '../BottomTab';
import Graph2 from './Graph2';

export default function Essence (props){

    const [month,setMonth] = useState(moment().month())
    const [year,setYears] = useState(moment().year())
    const [essences,setEssences] = useState([])
    const [error,setError] = useState(false)
    const [lastSixMonth,setLastSixMonth] = useState([])

    const [isGraph2,setIsGraph2] = useState(false)
    
    const [datasTotal,setDatasTotal] = useState([])
    const [datasKm,setDatasKm] = useState([])
    const [datasLitres,setDatasLitres] = useState([])
    
    
    useEffect(() => {
        fetchEssences()
    },[]);

    useEffect(()=>{
        getLastSixMonth(essences)
    },[month,year])


    const findKm = (year,month,tab) => {
        let res = 0
        lodash.forEach(tab,(e)=>{
            if(e.year == year && e.month == month){
                res = e.km
            }
        })
        return res
    }

    const findTotal = (year,month,tab) => {
        let res = 0
        lodash.forEach(tab,(e)=>{
            if(e.year == year && e.month == month){
                res = e.total
            }
        })
        return res
    }

    const findLitres = (year,month,tab) => {
        let res = 0
        lodash.forEach(tab,(e)=>{
            if(e.year == year && e.month == month){
                res = e.litres
            }
        })
        return res
    }


    const dataPrixLitres = {
        labels: lastSixMonth,
        datasets: [
          {
            label: '€',
            fill: true,
            lineTension: 0.3,
            backgroundColor: 'rgba(224, 62, 16, 0.3)',
            borderColor: 'rgba(224, 62, 16, 1)',
           
            pointBorderColor: 'rgba(224, 62, 16, 0.3)',
            pointBackgroundColor: 'rgba(224, 62, 16, 0.3)',
            pointBorderWidth: 5,
            
            pointHoverRadius: 6,
            pointHoverBackgroundColor: 'rgba(224, 62, 16, 0)',
            pointHoverBorderColor: 'rgba(224, 62, 16, 0.3)',
            data: datasTotal,
          },
          {
            label: 'Litres',
            fill: true,
            lineTension: 0.3,
            backgroundColor: 'rgba(0, 231, 212, 0.3)',
            borderColor: 'rgba(0, 231, 212, 1)',
           
            pointBorderColor: 'rgba(0, 231, 212, 0.3)',
            pointBackgroundColor: 'rgba(0, 231, 212, 0.3)',
            pointBorderWidth: 5,
            
            pointHoverRadius: 6,
            pointHoverBackgroundColor: 'rgba(0, 231, 212, 0)',
            pointHoverBorderColor: 'rgba(0, 231, 212, 0.3)',
            data:datasLitres,
          },
        ]
      };

      const dataAll = {
        labels: lastSixMonth,
        datasets: [
          {
            label: '€',
            fill: true,
            lineTension: 0.3,
            backgroundColor: 'rgba(224, 62, 16, 0.3)',
            borderColor: 'rgba(224, 62, 16, 1)',
           
            pointBorderColor: 'rgba(224, 62, 16, 0.3)',
            pointBackgroundColor: 'rgba(224, 62, 16, 0.3)',
            pointBorderWidth: 5,
            
            pointHoverRadius: 6,
            pointHoverBackgroundColor: 'rgba(224, 62, 16, 0)',
            pointHoverBorderColor: 'rgba(224, 62, 16, 0.3)',
            data: datasTotal,
          },
          {
            label: 'Kms',
            fill: true,
            lineTension: 0.3,
            backgroundColor: 'rgba(109, 16, 224, 0.3)',
            borderColor: 'rgba(109, 16, 224, 1)',
           
            pointBorderColor: 'rgba(109, 16, 224, 0.3)',
            pointBackgroundColor: 'rgba(109, 16, 224, 0.3)',
            pointBorderWidth: 5,
            
            pointHoverRadius: 6,
            pointHoverBackgroundColor: 'rgba(109, 16, 224, 0)',
            pointHoverBorderColor: 'rgba(109, 16, 224, 0.3)',
            data:datasKm,
          },
          {
            label: 'Litres',
            fill: true,
            lineTension: 0.3,
            backgroundColor: 'rgba(0, 231, 212, 0.3)',
            borderColor: 'rgba(0, 231, 212, 1)',
           
            pointBorderColor: 'rgba(0, 231, 212, 0.3)',
            pointBackgroundColor: 'rgba(0, 231, 212, 0.3)',
            pointBorderWidth: 5,
            
            pointHoverRadius: 6,
            pointHoverBackgroundColor: 'rgba(0, 231, 212, 0)',
            pointHoverBorderColor: 'rgba(0, 231, 212, 0.3)',
            data:datasLitres,
          },
        ]
      };

    const data = {
    labels: lastSixMonth,
    datasets: [
        {
        label: '€',
        data: datasTotal,
        backgroundColor: 'rgba(224, 62, 16, 1)',
        },
        {
        label: 'Kms',
        data:datasKm,
        backgroundColor: 'rgba(109, 16, 224, 1)',
        },
        {
        label: 'Litres',
        data:datasLitres,
        backgroundColor: 'rgba(0, 231, 212, 1)',
        },
    ],
    };

    const dataDoug = {
    labels: ['€ total', 'Kms total'],
    datasets: [
        {
        label: '# of Votes',
        data: [1590, 12807],
        backgroundColor: [
            'rgba(224, 62, 16, 1)',
            'rgba(109, 16, 224, 1)',
            
        ],
        borderColor: [
            'rgba(224, 62, 16, 1)',
            'rgba(109, 16, 224, 1)',
        ],
        borderWidth: 1,
        },
    ],
    };

    function fetchEssences(){
        fetch(process.env.REACT_APP_API_URL+"/essence/graph",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
            },
            body:JSON.stringify({user_id:localStorage.getItem("user")})
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            console.log(json.essences)
            if(json.err){
                setError(json.err)
            }else{
                setEssences(json.essences)
                getLastSixMonth(json.essences)
            }
            
        }).catch((error)=>{
            console.log(error)
        })
    }


    const count = [5,4,3,2,1,0]
    
    const getLastSixMonth = (tab) =>{
        let six = []
        let total = []
        let kms = []
        let litres = []
        switch(month) {
            case 0 :
                lodash.forEach(count,(m)=>{
                    if(m == 0){
                        six.push(moment().month(month).startOf("month").subtract(m, 'month').month())
                        total.push(findTotal(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        kms.push(findKm(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        litres.push( findLitres(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                    }else{
                        six.push(moment().month(month).startOf("month").subtract(m, 'month').month())
                        total.push(findTotal(year-1, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        kms.push(findKm(year-1, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        litres.push( findLitres(year-1, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                    }
                })
                break;
            case 1 :
                lodash.forEach(count,(m)=>{
                    if(m <= 1 ){
                        six.push(moment().month(month).startOf("month").subtract(m, 'month').month())
                        total.push(findTotal(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        kms.push(findKm(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        litres.push( findLitres(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                    }else{
                        six.push(moment().month(month).startOf("month").subtract(m, 'month').month())
                        total.push(findTotal(year-1, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        kms.push(findKm(year-1, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        litres.push( findLitres(year-1, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                    }
                })
                break;
            case 2 :
                lodash.forEach(count,(m)=>{
                    if(m <= 2){
                        six.push(moment().month(month).startOf("month").subtract(m, 'month').month())
                        total.push(findTotal(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        kms.push(findKm(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        litres.push( findLitres(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                    }else{
                        six.push(moment().month(month).startOf("month").subtract(m, 'month').month())
                        total.push(findTotal(year-1, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        kms.push(findKm(year-1, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        litres.push( findLitres(year-1, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                    }
                })
                break;
            case 3 :
                lodash.forEach(count,(m)=>{
                    if(m <= 3){
                        six.push(moment().month(month).startOf("month").subtract(m, 'month').month())
                        total.push(findTotal(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        kms.push(findKm(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        litres.push( findLitres(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                    }else{
                        six.push(moment().month(month).startOf("month").subtract(m, 'month').month())
                        total.push(findTotal(year-1, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        kms.push(findKm(year-1, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        litres.push( findLitres(year-1, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                    }
                })
                break;
            case 4 :
                lodash.forEach(count,(m)=>{
                    if(m <= 4){
                        six.push(moment().month(month).startOf("month").subtract(m, 'month').month())
                        total.push(findTotal(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        kms.push(findKm(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        litres.push( findLitres(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                    }else{
                        six.push(moment().month(month).startOf("month").subtract(m, 'month').month())
                        total.push(findTotal(year-1, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        kms.push(findKm(year-1, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        litres.push( findLitres(year-1, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                    }
                })
                break;
            case 5 :
            case 6 :
            case 7 :
            case 8 :
            case 9 :
            case 10 :
            case 11 :
                lodash.forEach(count,(m)=>{
                        six.push(moment().month(month).startOf("month").subtract(m, 'month').month())
                        total.push(findTotal(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        kms.push(findKm(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                        litres.push( findLitres(year, moment().month(month).startOf("month").subtract(m, 'month').month(),tab))
                })
                break;
        }

        console.log(six)

      let res = []
      lodash.forEach(six,(month)=>{
        lodash.forEach(months,(m)=>{
          if(month == m.index){
            res.push(m.libelle)
          }
        })
      })
      console.log(res)
      setLastSixMonth(res)
      setDatasKm(kms)
      setDatasTotal(total)
      setDatasLitres(litres)
    }

    const next = () =>{
        if(month < 11 ){
            setMonth(month + 1)
        }
    }

    const previous = () =>{
        if(month > 0 ){
            setMonth(month - 1)
        }
    }


    return (
        <>
        <div className='grid grid-cols-2  border-b border-third'>
            <div onClick={()=>setIsGraph2(false)} className={(!isGraph2 ? "bg-gray-300 " : "")+ ' flex flex-row py-2 justify-center text-third border-r border-third'}>6 mois</div>
            <div onClick={()=>setIsGraph2(true)} className={(isGraph2 ? "bg-gray-300 " : "")+  ' flex flex-row py-2 justify-center text-third'}>12 mois</div>
        </div>
        <BottomTab/>
        {isGraph2 ?
            <Graph2/>    
        :
            <>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-4 h-full">
                    <nav  className="mb-4 px-4 flex items-center justify-between sm:px-0">
                        
                        <div className={(year == 0 && "invisible ") + "w-0 flex-1 flex"}>
                            <div onClick={()=>setYears(year - 1)} className="text-white -mt-px border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm leading-5 font-medium cursor-pointer transition ease-in-out duration-150">
                                <svg className="mr-3 h-5 w-5 text-third" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                {year-1}
                            </div>
                        </div>

                        <div className="flex text-third justify-center -mt-px border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm leading-5 font-medium cursor-pointer transition ease-in-out duration-150">
                            {year}
                        </div>

                        <div className={(year == 11 && "invisible ")+"w-0 flex-1 flex justify-end"}>
                            <div onClick={()=>setYears(year + 1)} className="text-white -mt-px border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm leading-5 font-medium cursor-pointer transition ease-in-out duration-150">
                                {year + 1}
                                <svg className="ml-3 h-5 w-5 text-third" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </div>
                        </div>
                    </nav>

                    <nav className="mb-4 px-4 flex items-center justify-between sm:px-0">
                            <div className={(month == 0 && "invisible ") + "w-0 flex-1 flex"}>
                                <div onClick={previous} className="text-white -mt-px border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm leading-5 font-medium cursor-pointer transition ease-in-out duration-150">
                                    <svg className="mr-3 h-5 w-5 text-third" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    {showPreviousMonth(month)}
                                </div>
                            </div>
                        
                        <div className="text-third flex justify-center -mt-px border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm leading-5 font-medium cursor-pointer transition ease-in-out duration-150">
                            {months.map((m,i)=>{
                                if(month == m.index){
                                    return (
                                        <div key={i}>{m.libelle}</div>
                                    )
                                }
                            })}
                        </div>
                        <div className={(month == 11 && "invisible ")+"w-0 flex-1 flex justify-end"}>
                            <div onClick={next} className="text-white -mt-px border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm leading-5 font-medium cursor-pointer transition ease-in-out duration-150">
                                {showNextMonth(month)}
                                <svg className="ml-3 h-5 w-5 text-third" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </div>
                        </div>
                    </nav>
                </div>
                    <div className="flex items-center justify-center py-4 px-4 mb-12">
                        <div className="w-full">
                            <div className="flex flex-col">
                                <div className="mt-6">
                                    <Line data={dataPrixLitres} height={250} options={{maintainAspectRatio: false, scales: {y: {beginAtZero: true}}}} />
                                </div>
                                <div className="mt-6">
                                    <Line data={dataAll} height={250} options={{maintainAspectRatio: false, scales: {y: {beginAtZero: true}}}} />
                                </div>
                                <div className="mt-6">
                                    <Bar data={data} height={250} options={{ maintainAspectRatio: false}} />
                                </div>
                                <div className="mt-6">
                                    {/*<Doughnut data={dataDoug} height={250} options={{ maintainAspectRatio: false }}/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}


